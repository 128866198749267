import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Breakpoint } from '@helsenorge/designsystem-react';
import { hasAppVersionOrHigher } from '@helsenorge/framework-utils/cookie';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getErInnloggetViaHnApp } from '@helsenorge/framework-utils/hn-user';
import styles from './styles.module.scss';
const HeaderSkeletonContainer = props => (_jsx("span", { className: styles['header-skeleton-container'], children: _jsx("span", { className: styles['header-skeleton'], children: props.children }) }));
const HeaderSkeletonButton = props => (_jsx("span", { className: classNames(styles['header-skeleton__button'], props.size === 'lg' && styles['header-skeleton__button--lg'], props.size === 'md' && styles['header-skeleton__button--md'], props.pushSiblings === 'right' && styles['header-skeleton__button--right'], props.visibleFrom === Breakpoint.md && styles['header-skeleton__button--tablet'], props.visibleFrom === Breakpoint.lg && styles['header-skeleton__button--desktop']) }));
const HeaderSkeletonV2 = () => {
    const erInnloggetViaHnApp = getFeatureToggle('NyForside2025') && getErInnloggetViaHnApp() && hasAppVersionOrHigher('25.02.00');
    if (erInnloggetViaHnApp) {
        return (_jsxs(HeaderSkeletonContainer, { children: [_jsx(HeaderSkeletonButton, { size: "lg", pushSiblings: "right" }), _jsx(HeaderSkeletonButton, {}), _jsx(HeaderSkeletonButton, {})] }));
    }
    return (_jsxs(HeaderSkeletonContainer, { children: [_jsx(HeaderSkeletonButton, { size: "md" }), _jsx(HeaderSkeletonButton, {}), _jsx(HeaderSkeletonButton, { visibleFrom: Breakpoint.md }), _jsx(HeaderSkeletonButton, { pushSiblings: 'right' }), _jsx(HeaderSkeletonButton, { size: "lg", visibleFrom: Breakpoint.md }), _jsx(HeaderSkeletonButton, { size: "lg" })] }));
};
export default HeaderSkeletonV2;

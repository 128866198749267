/// <reference types="@helsenorge/framework-utils/types/hn"/>

import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';

import { MenuType } from '../../types/entities';

import HeaderSkeleton from '@helsenorge/core-cms/header-skeleton';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { isReferrer } from '@helsenorge/core-utils/referrer-utils';
import { getUserLanguage, useSetUserLanguageEvent } from '@helsenorge/framework-utils/hn-language';
import { getAssetsUrl } from '@helsenorge/framework-utils/hn-page';
import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import WebCompConsumer from '@helsenorge/framework-utils/web-component/consumer';
import { SubscribeContext, EventContext } from '@helsenorge/framework-utils/web-component/context';
import { HNeventSetUserLoading, VisPersonvelgerDetail } from '@helsenorge/framework-utils/web-component/events';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';
import WithStore from '@helsenorge/framework-utils/web-component/with-store';

import { globalStateContext, globalReducer } from '../../store';
import {
  getUser,
  erInnloggetOgErTjenester,
  getHeaderFooter,
  DispatchAction,
  getInnloggetData,
  setIsListenerReady,
} from '../../store/actions';
import { setLanguage } from '../../store/actions/language';
import { setVisPersonvelger } from '../../store/actions/personvelger';
import { GlobalState, initialState } from '../../store/initialState';
import { reducer } from '../../store/reducers';
import AppInvitationWrapper from '../app-invitation';
import Header from '../header';
import SkipToContent from '../skip-to-content';

import styles from './styles.module.scss';

interface Props {
  simplified?: string | boolean;
  anonymous?: string | boolean;
  hide?: string | boolean;
  language?: LanguageLocales;
}

interface State {
  isMenuActive: boolean;
  hasVarsel: boolean;
  menuType?: MenuType;
  prevMenuType?: MenuType;
  simplifiedHeader?: boolean;
  firstTimeLogin?: boolean;
  anonymousHeader: boolean;
  hiddenHeader?: boolean;
}

export const HeaderWrapper: React.FC<Props> = ({ simplified, anonymous, hide, language }) => {
  const subscribe = useContext(SubscribeContext);
  const globalState = useContext(globalStateContext);
  const globalDispatch = useContext(globalReducer);
  const eventContext = useContext(EventContext);

  const [state, setState] = useState<State>({
    hasVarsel: false,
    menuType: undefined,
    isMenuActive: false,
    prevMenuType: undefined,
    simplifiedHeader: isReferrer('oidc') || (simplified !== undefined && simplified !== 'false'),
    firstTimeLogin: false,
    anonymousHeader: anonymous !== undefined && anonymous !== 'false',
    hiddenHeader: hide !== undefined && hide !== 'false',
  });

  if (language === LanguageLocales.NORWEGIAN.toLowerCase()) {
    language = LanguageLocales.NORWEGIAN;
  }
  // Effect run on init
  useEffect(() => {
    if (window && window.HN) {
      const userLanguage = getUserLanguage() || language || LanguageLocales.NORWEGIAN;
      getHeaderFooter(globalDispatch, 'Det oppstod en feil ved henting av menyen.', userLanguage);
      setLanguage(globalDispatch, userLanguage);
      erInnloggetOgErTjenester(globalDispatch);

      subscribe('hn-webcomp-header-footer-event-setsimplifiedheader', (event: CustomEvent) => {
        const simplifiedHeader = event.detail.simplifiedHeader;
        setState(state => (state = { ...state, simplifiedHeader }));
      });
      subscribe(HeaderFooterEvents.setfirsttimelogin, (event: CustomEvent) => {
        const firstTimeLogin = event.detail.firstTimeLogin;
        setState({ ...state, firstTimeLogin });
      });
      subscribe(HeaderFooterEvents.setanonymousheader, (event: CustomEvent) => {
        const anonymousHeader = event.detail.anonymousHeader;
        setState(state => (state = { ...state, anonymousHeader }));
      });

      subscribe(HeaderFooterEvents.sethiddenheader, (event: CustomEvent) => {
        const hiddenHeader = event.detail.hiddenHeader;
        setState(state => (state = { ...state, hiddenHeader }));
      });
    }
  }, []);

  const handleVisPersonvelgerUpdate = (event: CustomEvent<VisPersonvelgerDetail>): void => {
    setVisPersonvelger(globalDispatch, event.detail.visPersonvelger);
  };

  useEffect(() => {
    window.addEventListener(HeaderFooterEvents.setvispersonvelger, handleVisPersonvelgerUpdate);

    return (): void => {
      window.removeEventListener(HeaderFooterEvents.setvispersonvelger, handleVisPersonvelgerUpdate);
    };
  }, []);

  useSetUserLanguageEvent(newLanguage => {
    if (erTjenester()) {
      getHeaderFooter(globalDispatch, 'Det oppstod en feil ved henting av menyen.', newLanguage);
      setLanguage(globalDispatch, newLanguage);
    }
  });

  // Effect for getting user data and meldinger
  useEffect(() => {
    if (globalState.isAuthorized && globalState.headerFooter) {
      if (globalState.isMinHelseTjenester) {
        getUser(globalDispatch, globalState.headerFooter.header.personvelger?.errorHentBruker ?? 'Feil under henting av bruker');
        HNeventSetUserLoading(true);
      } else {
        getInnloggetData(
          globalDispatch,
          globalState.headerFooter.header.personvelger?.errorInnloggetBruker ?? 'Feil under henting av data om innlogging'
        );
      }

      subscribe(HeaderFooterEvents.setlistenerready, () => {
        setIsListenerReady(globalDispatch);
      });
    }
  }, [globalState.isAuthorized, globalState.headerFooter]);

  useEffect(() => {
    eventContext(
      new CustomEvent(HeaderFooterEvents.setvarslinger, {
        detail: globalState,
      })
    );
  }, [globalState.islistenerReady, globalState.error?.hendelseError]);

  useEffect(() => {
    if (globalState.bruker) {
      HNeventSetUserLoading(false);
    }
  }, [globalState.bruker]);

  if (!globalState.headerFooter) {
    return <HeaderSkeleton />;
  }

  const setMenuType = (type: MenuType): void => {
    setState(prevState => ({ ...prevState, isMenuActive: true, menuType: type, prevMenuType: prevState.menuType }));
  };

  const closeMenu = (): void => {
    setState(prevState => ({ ...prevState, isMenuActive: false, menuType: undefined }));
  };

  const isBlueberry = globalState.isAuthorized && state.isMenuActive && state.menuType === MenuType.profile;
  const isCherry = state.isMenuActive && (state.menuType === MenuType.basic || state.menuType === MenuType.search);

  const getMenuColor = (): string => {
    if (isBlueberry) {
      return 'blueberry';
    }
    if (isCherry) {
      return 'cherry';
    }
    return 'unsetcolor';
  };

  const headerWrapperStyles = classNames(styles['header-wrapper'], {
    [styles['header-wrapper--active']]: state.isMenuActive,
    [styles['header-wrapper--blueberry']]: isBlueberry,
    [styles['header-wrapper--cherry']]: isCherry,
    [styles['header-wrapper--unsetcolor']]: !isCherry && !isBlueberry,
    [styles['header-wrapper--has-profile-button']]:
      !state.simplifiedHeader && !state.anonymousHeader && typeof globalState.bruker !== 'undefined',
  });
  const headerOverlayClasses = classNames(styles['header-overlay'], { [styles['header-overlay--active']]: state.isMenuActive });

  const renderHeader = (): React.ReactNode => (
    <div className={headerWrapperStyles}>
      <SkipToContent />
      <Header
        isActive={state.isMenuActive}
        isSimplified={state.simplifiedHeader}
        isFirstTimeLogin={state.firstTimeLogin}
        isAnonymous={state.anonymousHeader}
        menuType={state.menuType}
        setMenuType={setMenuType}
        closeMenu={closeMenu}
        menuColor={getMenuColor()}
      />
      <AppInvitationWrapper />

      <div className={headerOverlayClasses}></div>
    </div>
  );

  return (
    <>
      {!state.hiddenHeader && renderHeader()}

      <WebCompConsumer
        domain={`${getAssetsUrl()}/personvelgeren`}
        entryName="src/index.tsx"
        componentName="hn-webcomp-session"
        templateName="hn-webcomp-personvelger-template"
        includeHelsenorgeCss
        componentProps={{
          isauthorized: globalState.isAuthorized ? 'true' : 'false',
          lightboxinactivitytitle: globalState.headerFooter.header.fasteTekster.lightboxInactivityTitle,
          lightboxinactivitydescription: globalState.headerFooter.header.fasteTekster.lightboxInactivityDescription,
          lightboxinactivitytimer: globalState.headerFooter.header.fasteTekster.lightboxInactivityTimer,
          lightboxinactivitydoyouwanttostay: globalState.headerFooter.header.fasteTekster.lightboxInactivityDoYouWantToStay,
          lightboxinactivitybuttonyes: globalState.headerFooter.header.fasteTekster.lightboxInactivityButtonYes,
          lightboxinactivitybuttonsignout: globalState.headerFooter.header.fasteTekster.lightboxInactivityButtonSignOut,
          inactivitysignedouttitle: globalState.headerFooter.header.fasteTekster.inactivitySignedOutTitle,
          inactivitysignedoutbuttongotoroot: globalState.headerFooter.header.fasteTekster.inactivitySignedOutButtonGoToRoot,
          inactivitysignedoutbuttonsigninagain: globalState.headerFooter.header.fasteTekster.inactivitySignedOutButtonSignInAgain,
        }}
      />
    </>
  );
};
const props = {
  StateContext: globalStateContext,
  DispatchContext: globalReducer,
  reducer: reducer,
  initialState: initialState,
};

const ComponentWithStore: React.FC<WebcompProps> = optionalProps => {
  return (
    <WithStore<GlobalState, DispatchAction> {...props}>
      <HeaderWrapper {...optionalProps} />
    </WithStore>
  );
};

export default ComponentWithStore;

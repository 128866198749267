import { Dispatch } from 'react';

import { ActionTypes } from '../../types/entities';

import { DispatchAction } from '../actions';

export const setVisPersonvelger = (dispatch: Dispatch<DispatchAction>, visPersonvelger: boolean): void => {
  dispatch({ type: ActionTypes.GetUserAction, payload: { bruker: { visPersonvelger } } });
};

export const setPersonvelgerRedirectUrl = (dispatch: Dispatch<DispatchAction>, redirectUrl: string): void => {
  dispatch({ type: ActionTypes.SetPersonvelgerRedirectUrlAction, payload: { personvelgerRedirectUrl: redirectUrl } });
};

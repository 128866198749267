import React, { useContext } from 'react';

import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import Avatar from '@helsenorge/designsystem-react/components/Icons/Avatar';
import Settings from '@helsenorge/designsystem-react/components/Icons/Settings';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import fontStyles from '@helsenorge/designsystem-react/scss/typography.module.scss';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { safeFormatCET, DateFormat, loadLocale } from '@helsenorge/core-utils/date-fns-utils';
import { useBreakpoint, Breakpoint } from '@helsenorge/designsystem-react';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getAssetsUrl } from '@helsenorge/framework-utils/hn-page';
import { getErInnloggetViaHnAppUngdom } from '@helsenorge/framework-utils/hn-user';
import WebCompConsumer from '@helsenorge/framework-utils/web-component/consumer';

import MenuProfileLinks from './menu-profile-links';
import { globalStateContext } from '../../store';
import { hasSamtykke } from '../../utils/samtykke';
import LogoutButton from '../logout-button';

import styles from './styles.module.scss';

const transformLastLogonFormat = (time: string, locale?: LanguageLocales): string => {
  if (locale === LanguageLocales.NORWEGIAN_NYNORSK || locale === LanguageLocales.ENGLISH || locale === LanguageLocales.SAMI_NORTHERN) {
    loadLocale(locale);
  } else {
    loadLocale(LanguageLocales.NORWEGIAN);
  }

  return safeFormatCET(time, DateFormat.LongDateTime);
};

const MenuProfile: React.FC = () => {
  const globalState = useContext(globalStateContext);
  const breakpoint = useBreakpoint();
  const isMobileOrTablet = breakpoint === Breakpoint.xs || breakpoint === Breakpoint.sm || breakpoint === Breakpoint.md;
  const [personvelgerListeExpanded, setPersonvelgerExpanded] = React.useState(false);
  const userHasSamtykke = hasSamtykke(globalState.bruker?.personverninnstillinger);

  const renderSistInnlogget = (): React.JSX.Element | undefined => {
    if (globalState.bruker?.sistInnlogget) {
      return (
        <p className={styles.sistinnlogget}>
          {globalState.headerFooter.header.profileMenu.headerMenuProfileSistInnlogget}{' '}
          {transformLastLogonFormat(globalState.bruker?.sistInnlogget, globalState.language)}
        </p>
      );
    }
  };

  if (getErInnloggetViaHnAppUngdom()) {
    return (
      <div data-testid="menu-profile">
        <LogoutButton />
        <Spacer size={'m'} />
        {renderSistInnlogget()}
      </div>
    );
  }

  const personvelger = (
    <WebCompConsumer
      domain={`${getAssetsUrl()}/personvelgeren`}
      entryName="src/index.tsx"
      componentName="hn-webcomp-personvelger-component"
      templateName="hn-webcomp-personvelger-template"
      includeHelsenorgeCss
      componentProps={{
        redirecturl: globalState.personvelgerRedirectUrl,
      }}
    />
  );

  if (getFeatureToggle('NyForside2025')) {
    return (
      <div className={styles['menu-profile']} data-testid="menu-profile">
        {personvelger}
        <div>
          {globalState.bruker?.sistInnlogget && renderSistInnlogget()}
          <LogoutButton />
        </div>
      </div>
    );
  }

  return (
    <div className={styles['menu-profile']} data-testid="menu-profile">
      <ExpanderList color="blueberry" childPadding={false}>
        <ExpanderList.Expander
          icon={<Icon size={IconSize.XSmall} svgIcon={Avatar} />}
          title={globalState.headerFooter.header.profileMenu.headerMenuProfileRepresentasjonerTitle}
          className={isMobileOrTablet ? fontStyles.title4 : fontStyles.title3}
          onExpand={(): void => setPersonvelgerExpanded(true)}
        >
          {personvelgerListeExpanded && (
            <>
              <Spacer size={'s'} />
              {personvelger}
              <Spacer size={'xl'} />
            </>
          )}
        </ExpanderList.Expander>
        {userHasSamtykke && (
          <ExpanderList.Expander
            icon={<Icon svgIcon={Settings} />}
            title={globalState.headerFooter.header.profileMenu.headerMenuProfileLinksTitle}
            className={isMobileOrTablet ? fontStyles.title4 : fontStyles.title3}
          >
            <Spacer size={'s'} />
            <MenuProfileLinks />
            <Spacer size={'xl'} />
          </ExpanderList.Expander>
        )}
      </ExpanderList>
      <div>
        {globalState.bruker?.sistInnlogget && renderSistInnlogget()}
        <LogoutButton />
      </div>
    </div>
  );
};

export default MenuProfile;

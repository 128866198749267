import React, { useContext } from 'react';

import Login from '@helsenorge/designsystem-react/components/Icons/Login';

import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { getAppUrl } from '@helsenorge/framework-utils/url';
import { HNeventShowAppInvitation } from '@helsenorge/framework-utils/web-component/events';

import { globalStateContext } from '../../store';
import HeaderButton from '../header/header-button';

interface LoginButtonProps {
  redirectUrl?: string;
}

const LoginButton: React.FC<LoginButtonProps> = props => {
  const globalState = useContext(globalStateContext);
  const buttonRef = React.useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  if (globalState.isAuthorized) {
    return null;
  }

  const checkIsMobile = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    const appUrl = getAppUrl();

    if (appUrl) {
      e?.preventDefault();
      HNeventShowAppInvitation(
        () => {
          window.location.href = appUrl;
        },
        () => {
          handleLogin(e);
        },
        () => {
          buttonRef.current?.focus();
        }
      );
    } else {
      handleLogin(e);
    }
  };

  const handleLogin = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    trackNavigation('logg inn', '', 'sidetopp');
    if (globalState.headerFooter.header.fasteTekster.headerButtonLoginUrl) {
      const HelseNorgeUrlLogin = `${getTjenesterUrl()}${globalState.headerFooter.header.fasteTekster.headerButtonLoginUrl}`;

      if (props.redirectUrl) {
        window.location.replace(`${HelseNorgeUrlLogin}?Source=${props.redirectUrl}`);
      } else {
        window.location.replace(HelseNorgeUrlLogin);
      }
      e.preventDefault();
    }
  };

  return (
    <HeaderButton icon={Login} onClick={checkIsMobile} ref={buttonRef} testId={'login'}>
      {globalState.headerFooter.header.fasteTekster.headerButtonLogin}
    </HeaderButton>
  );
};

export default LoginButton;

import React, { useContext } from 'react';

import classNames from 'classnames';

import { MenuType } from '../../types/entities';

import Avatar from '@helsenorge/designsystem-react/components/Avatar';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';

import { useHover } from '@helsenorge/designsystem-react';
import { hasAppVersionOrHigher } from '@helsenorge/framework-utils/cookie';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getErInnloggetViaHnApp } from '@helsenorge/framework-utils/hn-user';

import { globalStateContext } from '../../store';
import Menu from '../menu';

import styles from './styles.module.scss';

interface ProfileButtonProps {
  headerRef?: React.RefObject<HTMLDivElement>;
  isActive?: boolean;
  menuColor?: string;
  onClick?: (type: MenuType) => void;
  onClose?: () => void;
  htmlMarkup?: 'a' | 'button';
  href?: string;
}
const ProfileButton: React.FC<ProfileButtonProps> = ({
  onClose,
  headerRef,
  isActive,
  menuColor,
  onClick,
  htmlMarkup = 'button',
  href,
}: ProfileButtonProps) => {
  const globalState = useContext(globalStateContext);
  const { hoverRef, isHovered } = useHover<HTMLAnchorElement & HTMLButtonElement>();

  const erInnloggetViaHnApp = getFeatureToggle('NyForside2025') && getErInnloggetViaHnApp() && hasAppVersionOrHigher('25.02.00');

  const profileButtonClasses = classNames(
    styles['profile-button'],
    !erInnloggetViaHnApp && styles['profile-button--web'],
    isActive && styles['profile-button--active']
  );

  const Component = htmlMarkup;

  return (
    <>
      <Component
        className={profileButtonClasses}
        onClick={(): void => (isActive ? onClose && onClose() : onClick && onClick(MenuType.profile))}
        href={href}
        aria-expanded={isActive}
        tabIndex={0}
        data-testid="profile-button"
        ref={hoverRef}
      >
        <Avatar size="xsmall" variant={globalState.bruker?.erRepresentasjon ? 'circle' : 'square'}>
          {globalState.bruker?.representertBruker ?? ''}
        </Avatar>
        <span className={styles['profile-button__text']}>
          {globalState.bruker?.representertBruker ? globalState.bruker?.representertBruker : ''}
        </span>
        {!erInnloggetViaHnApp && (
          <Icon
            className={styles['profile-button__icon']}
            size={IconSize.XSmall}
            svgIcon={isActive ? ChevronUp : ChevronDown}
            ariaLabel={
              isActive
                ? (globalState.headerFooter?.header.fasteTekster.closeMenu ?? 'Lukk')
                : (globalState.headerFooter?.header.fasteTekster.openMenu ?? 'Åpne')
            }
            isHovered={isHovered}
          />
        )}
      </Component>
      {isActive && <div className={styles['dividing-line']} />}
      {headerRef && (
        <Menu headerRef={headerRef} menuType={MenuType.profile} isActive={isActive} backgroundColor={menuColor} onClose={onClose} />
      )}
    </>
  );
};

export default ProfileButton;

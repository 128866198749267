import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';

import SurveyInvitation, { Survey } from '@helsenorge/core-cms/survey-invitation';
import { isReferrer } from '@helsenorge/core-utils/referrer-utils';
import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { FooterDataDetail } from '@helsenorge/framework-utils/web-component/events';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import { renderFooterColumn, renderSimplifiedFooterContent, setStickyFooter } from './footer-utils';

import styles from './styles.module.scss';

interface Props {
  hide?: string | boolean;
  simplified?: string | boolean;
  nomargin?: string | boolean;
}

const Footer: React.FC<Props & WebcompProps> = ({ hide, simplified, nomargin }) => {
  const subscribe = useContext(SubscribeContext);

  const [footerData, setFooterData] = useState<FooterDataDetail>();
  const [hiddenFooter, setHiddenFooter] = useState<boolean>(hide !== undefined && hide !== 'false');
  const [isSimplifiedFooter, setIsSimplifiedFooter] = useState<boolean>(
    isReferrer('oidc') || (simplified !== undefined && simplified !== 'false')
  );
  const [noMarginFooter, setNoMarginFooter] = useState<boolean>(nomargin !== undefined && nomargin !== 'false');
  const [simplifiedFooterText, setSimplifiedFooterText] = useState<string>();
  const [showFooter, setShowFooter] = useState(false);
  const [survey, setSurvey] = useState<Survey>();

  useEffect(() => {
    try {
      subscribe('hn-webcomp-header-footer-event-setsimplifiedfooter', (event: CustomEvent) => {
        setIsSimplifiedFooter(event.detail.simplifiedFooter);
        setSimplifiedFooterText(event.detail.simplifiedFooterText);
      });

      subscribe(HeaderFooterEvents.sethiddenfooter, (event: CustomEvent) => {
        setHiddenFooter(event.detail.hiddenFooter);
      });

      subscribe(HeaderFooterEvents.setnomarginfooter, (event: CustomEvent) => {
        setNoMarginFooter(event.detail.noMarginFooter);
      });

      subscribe(HeaderFooterEvents.setsurveys, (event: CustomEvent) => {
        if (event.detail.surveys?.length) {
          setSurvey(event.detail.surveys[0]);
        }
      });

      subscribe(HeaderFooterEvents.setfooterdata, (event: CustomEvent<FooterDataDetail>) => {
        setFooterData(event.detail);
      });
    } catch (e) {
      error('Feil i initialisering av footer', e);
    }

    // SetTimeout blir brukt for å hindre at footer kommer inn for tidlig
    setTimeout(
      () => {
        setShowFooter(true);
      },
      import.meta.env.MODE === 'test' ? 0 : 2000
    );
  }, []);

  useEffect(() => {
    setStickyFooter();
  }, [isSimplifiedFooter, simplifiedFooterText, hiddenFooter, showFooter, noMarginFooter]);

  const footerClassNames = classNames(styles.footer, {
    [styles['footer--normal']]: !isSimplifiedFooter,
    [styles['footer--no-margin']]: noMarginFooter,
  });
  const containerSimplifiedClassNames = classNames(styles['footer__container'], styles['footer__container--simplified']);
  const elementClassNames = classNames(
    styles['footer__element'],
    styles['footer__element--right'],
    styles['footer__element--simplified'],
    styles['footer__element__text--simplified'],
    styles['footer__element__text--large']
  );
  const footerColumnsClassNames = classNames(styles['footer__column--managing-editor']);

  if (!showFooter || hiddenFooter) {
    return null;
  }

  return (
    <>
      <footer
        className={footerClassNames}
        aria-label={footerData?.footerDTO?.fasteTekster?.footerAriaLabel ?? 'Bunnmeny'}
        lang={footerData?.language}
      >
        {isSimplifiedFooter ? (
          <div className={containerSimplifiedClassNames}>
            <div>{renderSimplifiedFooterContent(simplifiedFooterText, footerData?.footerDTO?.footerMenu?.supportHotline)}</div>
            <div>
              <div className={elementClassNames}>{footerData?.footerDTO?.footerMenu?.managingEditor}</div>
            </div>
          </div>
        ) : (
          <div className={styles['footer__container']}>
            {footerData?.footerDTO?.footerMenu?.footerColumns?.map((footerColumn, index: number) => {
              return renderFooterColumn(
                footerColumn,
                index,
                footerData?.footerDTO?.footerMenu?.supportHotline,
                footerData?.footerDTO?.footerMenu?.languageLinkList
              );
            })}
            <div className={footerColumnsClassNames}>
              {footerData?.footerDTO?.footerMenu?.managingEditor && (
                <div className={styles.footer__column__innerwrapper}>
                  <div
                    className={classNames(styles.footer__element, styles['footer__element__text'], styles['footer__element__text--large'])}
                  >
                    {footerData?.footerDTO?.footerMenu?.managingEditor}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </footer>
      {erTjenester() && <SurveyInvitation survey={survey} />}
    </>
  );
};

export default Footer;
